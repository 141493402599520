import React, { useState } from 'react';

function NavBar({ selectedPage }) {
    const [isOpen, setIsOpen] = useState(false);

    const pages = [
        { name: 'Home', href: '/' },
        { name: 'Feedback', href: '#feedback' },
        { name: 'Privacy Policy', href: 'privacyPolicies' },
        { name: 'Support', href: 'accountDeletion' },
    ];

    return (
        <div className={`fixed bottom-0 w-full bg-[#FD0000] text-white transition-transform duration-300 ${isOpen ? 'translate-y-0' : 'translate-y-full'} sm:translate-y-0 sm:h-auto sm:relative sm:w-auto sm:bg-[#FD0000]`}>
            <button
                className="absolute bottom-0 left-1/2 transform -translate-x-1/2 p-2 mb-2 sm:hidden bg-[#FD0000] text-white rounded"
                onClick={() => setIsOpen(!isOpen)}
            >
                {isOpen ? 'Close' : '▲'}
            </button>
            <ul className={`space-y-4 p-4 ${isOpen ? 'block' : 'hidden'} sm:block sm:space-y-4`}>
                {pages.map((page, index) => (
                    <li
                        key={index}
                        className={`hover:text-gray-300 ${selectedPage === index ? 'font-bold' : ''}`}
                    >
                        <a href={page.href}>{page.name}</a>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default NavBar;