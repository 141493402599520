import React from 'react';

function HomePage() {
    return (
        <div className="bg-gray-800 text-white min-h-screen p-8">
            <h1 className="text-4xl font-bold mb-4">Welcome to Hero Safety</h1>
            <p className="text-lg mb-6">
                Hero is an App that could be used to save your life in a pinch.<br />
                Going out in the woods and run out of cell range? When the Hero timer has started, it saves this information just incase and will notify your heroes if the timer runs out. After the timer has been stopped it will destroy the data associated.
            </p>
            <p className="text-lg">
                <span className="text-[#FD0000] font-semibold">Get Started:</span>
                <br />
                Navigate through the app using the menu on the left to explore more.
            </p>
        </div>
    );
}

export default HomePage; 