import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './components/nav-bar';
import HomePage from './components/home-page';
import PrivacyPolicies from './components/PrivacyPolicies';
import AccountDeletionPage from './components/account-deletion';


function App() {
  return (
    <Router>
      <div className="bg-gray-800 min-h-screen flex">
        <NavBar className='sticky top-0 z-10' />
        <div className="flex-1 p-4">
          <Routes >
            <Route path="/" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/privacyPolicies" element={<PrivacyPolicies />} />
            <Route path="/accountDeletion" element={<AccountDeletionPage />} />
            <Route path="/app-ads.txt" element='app-ads.txt' />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
