import React from 'react';

function AccountDeletionPage() {

    return (
        <div>
            <h1 className="font-bold text-2xl text-white">Account Deletion</h1>
            <br /><br />
            <div className='text-large font-medium text-[#FD0000] bg-white p-10 rounded'>
                <p>To delete the account, first navigate to settings page on the app, then proceede "Account Settings"</p>
                <br />
                <p>Once at the "Account Settings", be sure you want to delete your account for this will remove all data associated. Press "Delete Account"</p>
            </div>
        </div>
    );

}

export default AccountDeletionPage;